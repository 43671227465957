<template>
  <a-modal v-model:visible="isShow" title="修改" style="width: 100%; min-height: 100vh;" :footer="null" wrap-class-name="full-modal" :mask="false" :afterClose="modalClose">
    <a-row :gutter="24" style="margin-left: 10%;margin-right: 10%;">
      <a-col :span="24">
        <a-form-item label="">
          <a-input v-model:value="theme" placeholder="主题" :allowClear="true" style="width: 100%;" />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <md-editor
                v-model="content"
                :toolbars="toolbars"
                :showCodeRowNumber="true"
                :readOnly="false"
                @onUploadImg="onUploadImg"
                @onSave="updateBlog"
                theme="light"
                style="min-height: 80vh"
                editorId="blog-update"

        />
      </a-col>
    </a-row>

  </a-modal>
</template>

<script>
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';

import * as BlogApi from '@/api/BlogApi.js';
import {message} from "ant-design-vue";


export default {
  name: 'BlogUpdate',
  components: {
    MdEditor
  },
  data() {
    return {
      id: '',
      theme: '',
      content: '',

      toolbars: [
        'save',
        '-',
        'image',
        'link',
        '-',
        'quote',
        'title',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        '-',
        'codeRow',
        'code',
        '-',
        'table',
        'orderedList',
        'unorderedList',
        '-',
        'katex',
        'sup',
        'sub',
        '-',
        'mermaid',
        '-',
        'revoke',
        'next',
        '=',
        'preview',
        'htmlPreview',
        '-',
        'pageFullscreen',
        'catalog',
      ],

      isShow: false,


    }
  },

  methods: {
    modalClose() {
      this.id = '';
      this.theme = '';
      this.content = '';
    },

    blogDetails(id) {
      this.id = id;
      BlogApi['blogDetailsApi'](`id=${id}`)
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        if (code === 200) {
          this.theme = result.data.data['theme'];
          this.content = result.data.data['content'];
          this.isShow = true;
        } else {
          message.error(msg);
        }

      })
      .catch((e) => {
        message.error(e.message);
      })

    },

    onUploadImg(obj) {
      console.log(obj)
    },

    updateBlog() {
      let params = {id: this.id, theme: this.theme, content: this.content};
      BlogApi['blogUpdateApi'](params)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            message.success(msg);
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })
    },

  },

  created() {
  },

  mounted() {

  }
}
</script>


<style>
.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}

/*隐藏同步滚动*/
#blog-update .md-editor-footer-right {
  display: none;
}

/*设置文档宽*/
#blog-update .md-editor-content {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

/*设置目录宽*/
#blog-update .md-editor-catalog-editor {
  width: 50%;
}

</style>
