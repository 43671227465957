<template>
  <MainPanel>
    <a-card style="width: 100%; min-height: 100vh;">
      <a-row :gutter="24">
        <a-col :span="24" style="text-align: center">
          <a-form-item label="">
            <a-input v-model:value="blogPagingParams.theme" placeholder="主题" :allowClear="true" style="width: 20%;" size="large"/>
            <a-button type="primary" @click="onSearch" size="large">搜索</a-button>
          </a-form-item>
        </a-col>
      </a-row>

      <a-card :loading="loading" style="margin-bottom: 20px;">
        <a-card-grid style="width: 25%; text-align: center;" v-for="(blog, index) in this.blogList" :key="blog.id" >
          <a-space>
            <a-tag color="green">
              {{ index + 1 + (blogPagingParams.currentPage - 1) * blogPagingParams.pageSize }}
            </a-tag>
            <a @click="details(blog.id, blog.theme)">{{ blog.theme }}</a>
          </a-space>
          <div style="float: right">
            <a><a-tag color="processing" @click="onUpdate(blog.id)">修改</a-tag></a>
            <a-popconfirm
                    title="确认删除？"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="() => onDelete([blog.id])">
              <a><a-tag color="error">删除</a-tag></a>
            </a-popconfirm>
          </div>
        </a-card-grid>
      </a-card>

      <a-pagination :current="blogPagingParams.currentPage" :defaultPageSize="blogPagingParams.pageSize" :total="total" @change="onPagination"
                    style="text-align: right; margin-bottom: 20px;"
                    show-size-changer show-quick-jumper :show-total="total => `共 ${total} 条数据`" />

      <BlogDetails ref="details"></BlogDetails>

      <BlogUpdate ref="update"></BlogUpdate>

    </a-card>
  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";
import * as BlogApi from '@/api/BlogApi.js';
import {message} from "ant-design-vue";
import BlogDetails from "./BlogDetails";
import BlogUpdate from "./BlogUpdate";

export default {
  name: 'BlogList',
  components: {
    MainPanel,
    BlogDetails,
    BlogUpdate,
  },
  data() {
    return {
      loading: false,

      blogPagingParams: {
        theme: '',
        currentPage: 1,
        pageSize: 50,
      },

      total: 0,
      blogList: [],

    }
  },

  methods: {
    onSearch() {
      this.blogPagingParams.currentPage = 1;
      this.blogPaging();
    },

    onPagination(currentPage, pageSize) {
      this.blogPagingParams.currentPage = currentPage;
      this.blogPagingParams.pageSize = pageSize;
      this.blogPaging();
    },

    blogPaging() {
      this.loading = true;
      BlogApi['blogPagingApi'](this.blogPagingParams)
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        if (code === 200) {
          this.blogList = result.data.data['list'];
          this.total = parseInt(result.data.data.total);
        } else {
          message.error(msg);
        }

      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        this.loading = false;
      })
    },

    details(id, theme) {
      this.$refs.details.blogDetails(id, theme);
    },

    onUpdate(id) {
      this.$refs.update.blogDetails(id);
    },

    onDelete(idList) {
      let params = {idList: idList};
      BlogApi['blogDeleteApi'](params)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            message.success(msg);
            this.blogPaging();
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })
    },

  },

  created() {
    this.blogPaging();
  },

  mounted() {

  }
}
</script>
